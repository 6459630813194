import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sidebar", "sidebar_collapser"];

  connect() {}

  sidebarTargetConnected() {
    var sidebar_collapse = localStorage.getItem('sidebar_collapse')
    if(sidebar_collapse == 'true') $(this.sidebarTarget).addClass('collapse')
  }

  sidebar_collapserTargetConnected() {
    var sidebar_collapse = localStorage.getItem('sidebar_collapse')
    if(sidebar_collapse == 'true')  $(this.sidebar_collapserTarget).find('i').addClass('fa-forward')
    else                            $(this.sidebar_collapserTarget).find('i').addClass('fa-backward')
  }

  collapse_sidebar(event) {
    event.preventDefault();
    $(this.sidebarTarget).toggleClass('collapse')
    localStorage.setItem('sidebar_collapse', $(this.sidebarTarget).hasClass('collapse'))
    $(this.sidebar_collapserTarget).find('i').toggleClass('fa-forward').toggleClass('fa-backward')
  }
}