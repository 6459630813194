// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "./jquery"
import "jquery-ui"
import "jquery-ui/ui/widgets/datepicker"
import "./nprogress"
import "tablednd"
import "bootstrap-daterangepicker"
import 'bootstrap-datepicker'
import "./daterangepicker"
import "./datepicker"
import "./typeahead.bundle.js"
import "./typeahead"

window.Rails = require("@rails/ujs")
Rails.start()

export function wait_ms(ms){
   var start = new Date().getTime();
   var end = start;
   while(end < start + ms) {
     end = new Date().getTime();
  }
}