import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "source", "target" ];

  connect() {}

  handleSelectChange() {
    this.populateSelect(this.sourceTarget.value);
  }

  populateSelect(sourceId, targetId = null) {
    var source_url = this.sourceTarget.dataset.sourceUrl.replace(":id", sourceId)
    get(source_url, {
      responseKind: "turbo-stream",
      query: {target_id: this.targetTarget.id}
    })
  }
}