import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "notice", "error", "notice_p" , "error_p", "flashDiv"];

  connect() {
    var message
    if(message = this.noticeTarget.dataset.message) this.displayNotice(message)
    if(message = this.errorTarget.dataset.message)  this.displayError(message)
  }
  
  displayNotice(msg)
  { var notice_p = this.notice_pTarget
    var notice = this.noticeTarget
    if(msg) $(notice_p).html(msg);
    $(notice).hover(function() {
      $(this).stop().fadeOut(600);
    });
    $(notice,notice_p).stop(true,true);  
    $(notice).css('height', 'auto');
    $(notice_p).show();
    var barh = $(notice).height();
    $(notice_p).hide();
    $(notice).delay(200).show().animate({height: barh},500, function() {
      $(notice_p).fadeIn(500);
      $(notice_p).delay(3000).fadeOut(1000, function() {
          $(notice).slideUp(1500,function() { 
          });
      });
    }); 
  }

  displayError(msg)
  { var error_p = this.error_pTarget
    var error = this.errorTarget
    if(msg) $(error_p).html(msg);
    $(error).click(function() {
      $(error,error_p).stop().fadeOut(500,function() {
      });
    });
    $(error,error_p).stop(true,true);  
    $(error).css('height', 'auto');
    $(error_p).show();
    var barh = $(error).height();
    $(error_p).hide();
    $(error).delay(200).show().animate({height: barh},500, function() {
      $(error_p).fadeIn(500);
      $(error_p).delay(10000).fadeOut(500, function() {
          $(error).slideUp(1500,function() {
            //$(error).css({height: 'auto'});
          });
      });
    }); 
  };

}