import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tabs","navlink"];

  connect() {
    var nv_clone = null;
    var obj = $(this.tabsTarget);
    $(window).scroll(function (event) {
      var y = $(this).scrollTop();
      if(y > 0)
      { if(nv_clone == null)
        { nv_clone = obj.clone(true);  //metter clone(true,true) rallenta quando scollo 
          nv_clone.addClass('fixed_navtabs');
          nv_clone.prependTo($('main'));
          $(obj).css('visibility','hidden');
        }
      } 
      else 
      { if(nv_clone != null)
        { nv_clone.remove();
          nv_clone = null;
          $(obj).css('visibility','visible');
        } 
      }
    });
  }

  tabsTargetConnected() {
    var that = this

    $(this.navlinkTargets).contextmenu(function() {
      return false;
    });
    $(this.navlinkTargets).mousedown(function(e){
      if(e.which === 3 && !$(this).hasClass('tab_close') || e.which === 1 && $(this).hasClass('tab_close'))
      { $.ajax({type: "GET",
            dataType: 'html',
            url: that.tabsTarget.dataset.closePath,
            data: {
              id: $(this).attr('uuid')
            },
        }).done(function( data ) {
            window.location.replace(data);
          });
        e.preventDefault();
      }
    });
  }
}